<template>
  <div>
    <b-card
      v-if="isLoadingAccountInfo"
    >
      <custom-loading></custom-loading>
    </b-card>

    <b-card
      v-else
    >

      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="userData.avatar"
            :text="avatarText(userData.name)"
            size="90px"
            rounded
          />
        </template>
        <h4 class="mb-1">
          {{ userData.name }}
        </h4>
        <!-- <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >
            <span class="d-none d-sm-inline">Update</span>
            <feather-icon
              icon="EditIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
          >
            <span class="d-none d-sm-inline">Remove</span>
            <feather-icon
              icon="TrashIcon"
              class="d-inline d-sm-none"
            />
          </b-button>
        </div> -->
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form
        id="account-setting-info"
      >
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                readonly
                id="username"
                v-model="userData.userName"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Name"
              label-for="full-name"
            >
              <b-form-input
                readonly
                id="full-name"
                v-model="userData.name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                readonly
                id="email"
                v-model="userData.mail"
                type="email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
            >
              <!-- <v-select
                v-model="userData.role"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              /> -->
              <b-form-input
                readonly
                id="user-role"
                v-model="userData.role"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                disabled
                v-model="userData.isEnabled"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col> -->

        </b-row>
      </b-form>

      <!-- PERMISSION TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Permisos para cargar archivos</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :fields="tableColumns"
          :items="userData.permissions"
        >
          <template #cell(conditions)="data">
            {{ data.value.area_name }}
          </template>
          <template #cell(isEnabled)="data">
            <b-form-checkbox
              disabled
              v-model="data.item.isEnabled"
              :value="1"
              :unchecked-value="0"
            />
          </template>
        </b-table>
      </b-card>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BAlert, BLink,
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { ref, toRefs, watch } from 'vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import CustomLoading from '@/modules/common/CustomLoading.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,

    vSelect,
    CustomLoading,
  },
  props: {
    userData: {
      type: Object,
      default: {},
      required: true,
    },
    isLoadingAccountInfo: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  setup(props) {

    const {
      userData,
      isLoadingAccountInfo
    } = toRefs(props)

    const statusOptions = [
      { label: 'Activo', value: 1 },
      { label: 'Inactivo', value: 0},
    ]

    const tableColumns = [
      { key: 'conditions', label: 'area'},
      { key: 'isEnabled', label: 'modificar'},
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.value.avatar = base64
    })

    watch(isLoadingAccountInfo, (newValue, oldValue) => {
      isLoadingAccountInfo.value = newValue
    })

    return {
      avatarText,
      statusOptions,
      tableColumns,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
#account-setting-info input.form-control[readonly=readonly] {
  opacity: 1;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
