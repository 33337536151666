<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="changePasswordRules">
      <b-form
        method="POST"
        class="auth-change-password-form mt-2"
        @submit.prevent="changePassword"
      >

        <!-- old password -->
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="change-password-old"
            >
              <validation-provider
                #default="{ errors }"
                name="old-password"
                vid="old-assword"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="change-password-old"
                    v-model="passwordValueOld"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="change-password-old"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconOld"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- new password -->
        <b-row>
          <b-col md="6" >
            
            <b-form-group
              label="New Password"
              label-for="change-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="new-password"
                vid="new-password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="change-password-new"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="change-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- confirm password -->
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="change-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:new-password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="change-password-confirm"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="change-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIconRetype"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="2">
            <!-- submit button -->
            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              block
              class="my-1"
            >
              <b-spinner small />
              <span> Loading...</span>
            </b-button>
            <b-button
              v-else
              block
              type="submit"
              variant="primary"
              class="my-1"
            >
              Cambiar Password
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BButton,
  BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useLogout } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { ref, computed, getCurrentInstance } from 'vue'
import axios from '@axios'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BInputGroup,
    BRow, 
    BCol,
    BSpinner,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    userId: {
      type: Number,
      required: true,
      default: 0
    }
  },
  setup(props) {
    const isLoading = ref(false)
    const changePasswordRules = ref('')
    const passwordValueOld = ref('')
    const newPasswordValue = ref('')
    const retypePassword = ref('')
    const passwordFieldTypeOld = ref('password')
    const passwordFieldTypeNew = ref('password')
    const passwordFieldTypeRetype = ref('password')
    
    // root
    const vm = getCurrentInstance().proxy

    // get logout method
    const {
      logoutCurrentUser
    } = useLogout()

    // computed
    const passwordToggleIconOld = computed( () => {
      return passwordFieldTypeOld.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    const passwordToggleIconNew = computed( () => {
      return passwordFieldTypeNew.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    const passwordToggleIconRetype = computed( () => {
      return passwordFieldTypeRetype.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    })

    // methods
    const togglePasswordOld = () => {
      passwordFieldTypeOld.value = passwordFieldTypeOld.value === 'password' ? 'text' : 'password'
    }

    const togglePasswordNew = () => {
      passwordFieldTypeNew.value = passwordFieldTypeNew.value === 'password' ? 'text' : 'password'
    }
    const togglePasswordRetype = () => {
      passwordFieldTypeRetype.value = passwordFieldTypeRetype.value === 'password' ? 'text' : 'password'
    }

    const changePassword = () => {
      changePasswordRules.value.validate().then(success => {
        if (success) {
          isLoading.value = true

          axios
          .post(`${process.env.VUE_APP_BASE_URL}/change_pwd`, 
            { 
              user_id: props.userId,
              old_password: passwordValueOld.value,
              new_password: newPasswordValue.value,
              confirm_password: retypePassword.value,
            }
          )
          .then(response => { 
            // console.log(response)
            vm.$swal({
              title: 'Password cambiado!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
              willClose: logoutCurrentUser
            })   

          })
          .catch(error => {
            console.log(error)
            vm.$swal({
              title: 'Erro al cambiar password!',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .finally(() => {
            isLoading.value = false
          })
        }
      })
    }

    return {
      isLoading,
      required,
      changePasswordRules,
      passwordValueOld,
      newPasswordValue,
      retypePassword,
      passwordFieldTypeOld,
      passwordFieldTypeNew,
      passwordFieldTypeRetype,

      passwordToggleIconOld,
      passwordToggleIconNew,
      passwordToggleIconRetype,

      togglePasswordOld,
      togglePasswordNew,
      togglePasswordRetype,
      changePassword,

    }

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>